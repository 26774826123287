import { simplifiedRuledPriceFragment } from '#fragments/simplified_ruled_prices';
import { gql } from '@apollo/client/core';

export const SIMPLIFIED_RULED_PRICE_QUERY = gql`
  ${simplifiedRuledPriceFragment}
  query GetSimplifiedRuledPrice($id: bigint!) {
    ai_simplified_ruled_prices_by_pk(id: $id) {
      ...SimplifiedRuledPriceFragment
    }
  }
`;

export const SIMPLIFIED_RULED_PRICES_QUERY = gql`
  ${simplifiedRuledPriceFragment}
  query GetSimplifiedRuledPrices($client_id: bigint!) {
    ai_simplified_ruled_prices(where: { client_id: { _eq: $client_id } }) {
      ...SimplifiedRuledPriceFragment
    }
  }
`;

export const SIMPLIFIED_RULED_PRICE_UPSERT = gql`
  ${simplifiedRuledPriceFragment}
  mutation UpsertSimplifiedRuledPrice($object: ai_simplified_ruled_prices_insert_input!) {
    insert_ai_simplified_ruled_prices_one(
      object: $object
      on_conflict: {
        constraint: simplified_ruled_prices_pkey
        update_columns: [name, description, reference, fields]
      }
    ) {
      ...SimplifiedRuledPriceFragment
    }
  }
`;
