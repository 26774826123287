import { gql } from '@apollo/client/core';

export const simplifiedRuledPriceFragment = gql`
  fragment SimplifiedRuledPriceFragment on ai_simplified_ruled_prices {
    id
    client_id
    name
    description
    reference
    fields
    _created_at
    _updated_at
  }
`;
