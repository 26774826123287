import { usersStore } from '~/stores/users';

export enum FeatureName {
  insights = 'insights',
  exportCsv = 'exportCsv',
  dashboard = 'dashboard',
  aiAgent = 'aiAgent',
  ruledPrice = 'ruledPrice',
  addElementInAddModal = 'addElementInAddModal',
  draftAgent = 'draftAgent',
};
interface FeatureFlags extends Record<FeatureName, boolean> {};

const clientFeaturesEnabled: Record<FeatureName, number[]> = {
  [FeatureName.insights]: [2, 9],
  [FeatureName.exportCsv]: [],
  [FeatureName.dashboard]: [4],
  [FeatureName.aiAgent]: [],
  [FeatureName.ruledPrice]: [10],
  [FeatureName.addElementInAddModal]: [2],
  [FeatureName.draftAgent]: [],
};

const isStagingOrDev = import.meta.env.MODE !== 'production';

export const useFeaturesStore = defineStore('features', () => {
  const userStore = usersStore();
  const { clientId, isAdmin } = storeToRefs(userStore);
  function isClientEnabled(featureName: FeatureName) {
    return import.meta.env.MODE === 'production' && clientFeaturesEnabled[featureName].includes(clientId.value || 0);
  };

  const features = computed(() => ({
    [FeatureName.insights]: isStagingOrDev || isClientEnabled(FeatureName.insights) || isAdmin.value,
    [FeatureName.exportCsv]: isStagingOrDev || isClientEnabled(FeatureName.exportCsv),
    [FeatureName.dashboard]: isStagingOrDev || isClientEnabled(FeatureName.dashboard),
    [FeatureName.aiAgent]: isStagingOrDev || isClientEnabled(FeatureName.aiAgent) || isAdmin.value,
    [FeatureName.ruledPrice]: isStagingOrDev || isClientEnabled(FeatureName.ruledPrice),
    [FeatureName.addElementInAddModal]: isStagingOrDev || isClientEnabled(FeatureName.addElementInAddModal) || isAdmin.value,
    [FeatureName.draftAgent]: isStagingOrDev || isClientEnabled(FeatureName.draftAgent),
  } as FeatureFlags));
  const isEnabled = (feature: FeatureName) => !!features.value[feature];

  return {
    features,
    isEnabled,
  };
});
